import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/UI/Layout"
import Navbar from "../../components/UI/Navbar"
import Seo from "../../components/Seo"
import ServicePage from "../../components/templates/ServicePage"


const query = graphql`
  {
    allContentfulInstallationImages(
      filter: {
        metadata: {
          tags: { elemMatch: { contentful_id: { eq: "waterfallsStreams" } } }
        }
      }
    ) {
      nodes {
        id
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
          id
          title
        }
      }
    }
  }
`


export default function WaterfallsAndStreamsPage() {
  const title = `Waterfalls & Stream` // Singular (not plural)
  const {
    allContentfulInstallationImages: { nodes: image },
  } = useStaticQuery(query)

  const imageList = image[0].image


  return (
    <Layout>
      <Navbar />
      <Seo
        title={`${title}s`}
        description={`Custom ${title} builder in Oregon. Waterfalls and streams provide the sights and sounds to create a relaxing outdoor space.`}
      />

      <ServicePage
        title={title}
        imageList={imageList}
        img={
          <StaticImage
            src="../../assets/images/Waterfall and Pond_Andreatta Waterscapes_Rogue Valley Oregon.jpg"
            quality={90}
            loading="eager"
            style={{
              position: "absolute",
              width: "100%",
              zIndex: 1,
              height: "21rem",
            }}
            objectFit="cover"
            objectPosition="50% 40%"
            alt="Backyard Waterfall Central Point Oregon"
          />
        }
        tagline={`Bring nature to your backyard`}
        body={[
          {
            id: 0,
            type: "paragraph",
            content: `The immediate draw to almost every water feature we build is the cascading sound of the waterfall as it tumbles over the rocks and splashes down into its basin. The shape of the rocks, the height and number of drops, and the direction it faces, all add to the appeal of your waterfall. Getting those elements right is what makes the difference between a mediocre waterfall and one that will make your interaction with your yard magical.`,
          },
          {
            id: 1,
            type: "paragraph",
            content: `Waterfall construction is a combination of both technical expertise and artistic expression. A waterfall serves a practical function in filtering and oxygenating a pond, but it also provides a habitat for wildlife to drink and bathe in. You will often see finches and other birds perched on the branches of aquatic plants and splashing in the pools of the falls.`,
          },
          {
            id: 2,
            type: "paragraph",
            content: `Either as a standalone landscape water feature or as part of a pond, waterfalls and streams provide the sights and sounds that people are searching for to create a relaxing outdoor space.`,
          },
        ]}
      />
    </Layout>
  )
}
